import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3e24c5f6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "cont-nothing-box"
};
const _hoisted_2 = {
  class: "cont-nothing"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("i", {
    style: _normalizeStyle(`background-image: url(${require('@assets/images/iconfont/' + $props.img + '.png')})`)
  }, null, 4), _createElementVNode("span", null, _toDisplayString($props.message), 1), $props.needLogin && !_ctx.userId ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "btn",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.goLogin && $options.goLogin(...args))
  }, "登录")) : _createCommentVNode("", true), $props.showExtraBtn ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "btn",
    onClick: _cache[1] || (_cache[1] = (...args) => $options.confirm && $options.confirm(...args))
  }, _toDisplayString($props.btnTxt), 1)) : _createCommentVNode("", true)])]);
}