import { mapState } from 'vuex';
import { goToLogin } from "@/utils/user";
export default {
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  props: {
    showExtraBtn: {
      type: Boolean,
      default: false
    },
    btnTxt: {
      type: String,
      default: '发现更多项目'
    },
    message: {
      // 提示信息
      type: String,
      default: ''
    },
    needLogin: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
      default: 'icon_nopro'
    }
  },
  methods: {
    goLogin() {
      goToLogin();
    },
    confirm() {
      this.$emit("confirm");
    }
  }
};